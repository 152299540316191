<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_7.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 7 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10 w-2/3" v-if="!editable">

        <div class="m-auto" style="max-width: 800px;">
          <h2 class="mb-9">{{ firstName }}’s Travels</h2>

          <img
            class="m-auto mb-5"
            src="@/assets/images/undraw/travel_mode.svg"
          >

          <p class="mb-6 text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Adipiscing enim eu turpis egestas pretium aenean pharetra magna ac. Vel pharetra vel turpis nunc eget. Felis eget nunc lobortis mattis aliquam faucibus purus in. Malesuada fames ac turpis egestas sed tempus. Purus gravida quis blandit turpis cursus in hac. </p>
        </div>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'v2.profile.images'}"
            class="text-primary float-left"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left_blue.svg"
              class="inline"
            >
            Back
          </router-link>

          <router-link
            :to="{name: 'v2.profile.travels'}"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-10"
          >
            Finish Later
          </router-link>

          <a
            href="#"
            class="btn btn-primary btn-large align-self-center me-auto"
            @click="changeMode()"
          >
            Onwards
          </a>

          <router-link
            :to="{name: 'v2.profile.notable_experiences'}"
            class="text-primary float-right"
          >
            Skip

            <img
              src="@/assets/images/icons/ic_chevron_right_blue.svg"
              class="inline"
            >
          </router-link>
        </div>
      </div>

      <div class="m-auto pb-10 w-2/3" v-if="editable">

        <h2 class="mb-4">{{ firstName }}'s Travels</h2>

        <a href="#" class="text-primary font-bold">Why do you need all this data?</a>

        <form
          class="mt-10 mb-10"
          @submit.prevent="register"
        >

          <div
            v-if="errorMessage"
            class="flex items-center text-dark text-sm mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-10">
            <div class="form-check w-1/2 inline-block text-right">
              <label class="form-check-label" for="flexCheckIndeterminate">
                Show
              </label>

              <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="mb-10">
            <input
              v-model="location"
              type="text"
              class="form-control w-1/2"
              placeholder="Location"
              required
            >
          </div>

          <div class="mb-10">
            <div class="inline-block w-1/2">
              <div class="inline-block w-1/2 pr-5">
                <div>
                  <span class="float-left">Add Image</span>

                  <img
                    src="@/assets/images/icons/ic_download.svg"
                    class="inline-block float-right mr-2"
                  >
                </div>

                <img
                  src="@/assets/images/image_placeholder.png"
                  class="mr-2 w-full"
                >
              </div>

              <textarea
                v-model="description"
                rows="8"
                cols="10"
                class="form-control w-1/2 p-2"
                placeholder="Tell us more"
              ></textarea>
            </div>
          </div>

          <div class="mb-10">
            <input
              v-model="dates"
              type="text"
              class="form-control w-1/2"
              placeholder="Dates Traveled"
              required
            >
          </div>

          <div class="mb-10">
            <input
              v-model="travel_partner"
              type="text"
              class="form-control w-1/2"
              placeholder="Who did you travel with?"
              required
            >
          </div>
        </form>

        <div class="mb-10">
          <a
            href="#"
            class="text-primary align-self-center me-auto"
          >
            Add Another

            <img
              src="@/assets/images/icons/ic_plus.svg"
              class="inline-block"
            >
          </a>
        </div>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'v2.profile.images'}"
            class="text-primary float-left"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left_blue.svg"
              class="inline"
            >
            Back
          </router-link>

          <a
            href="#"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-10"
            @click="changeMode()"
          >
            Finish Later
          </a>

          <a
            href="#"
            class="btn btn-primary btn-large align-self-center me-auto"
            @click="changeMode()"
          >
            Onwards
          </a>

          <router-link
            :to="{name: 'v2.profile.notable_experiences'}"
            class="text-primary float-right"
          >
            Skip

            <img
              src="@/assets/images/icons/ic_chevron_right_blue.svg"
              class="inline"
            >
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Travels',
  data() {
    return {
      firstName: '',
      location: '',
      image: '',
      description: '',
      dates: '',
      travel_partner: '',
      editable: false,
      travels: [],
      uuid: null
    };
  },
  methods: {
    changeMode: function() {
      this.editable = !this.editable;
    },
    getTravels() {
      axios.get(process.env.VUE_APP_API_URL + '/attributes/travel/' + this.uuid, {
        headers: {
          'Authorization': `Bearer`
        }
      })
      .then((response) => {
        this.travels = response.data.attributes;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    submit() {
      let data = {
        uuid: this.uuid,
        node_type: 'image',
        attributes: {
          title: this.title,
          description: this.description,
          image: this.image
        }
      };

      axios.post(process.env.VUE_APP_API_URL + '/attribute', data, {
        headers: {
          'Authorization': `Bearer`
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    let firstName = localStorage.getItem('preferredname');
    this.uuid = localStorage.getItem('userUuid');
    
    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }

    this.getTravels();
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
</style>
